<template>
    <div>
<v-card flat>
    <v-card-text>
        <v-row>
            <v-col cols="12" sm="12" md="6"></v-col>
            <v-col cols="12" sm="12" md="6">
                <v-card flat outlined>
                    <v-card-title>Subscriptions</v-card-title>
                    <v-card-text></v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card-text>
</v-card>
    </div>
</template>
<script>
export default {
    data: ()=>({

    })
}
</script>